import * as React from "react";
import styled from "styled-components";
import GoogleMapReact from "google-map-react";

import { Flex, Text } from "../styled";
import Wrapper from "./wrapper";
import Button from "./button";

import logo from "../images/wkm_logo.svg";

const Footer = ({ className }) => {
  return (
    <>
      <div style={{ height: "70vh", width: "100%" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyATib5ym_7qFdLLJDb6UZZ8TCYxN_zr6IY" }}
          defaultCenter={{
            lat: 51.2226153,
            lng: 6.50869,
          }}
          defaultZoom={12}
          options={{
            styles: [
              {
                stylers: [
                  { saturation: -100 },
                  { gamma: 0.8 },
                  { lightness: 24 },
                  { visibility: "on" },
                ],
              },
            ],
          }}
        >
          <Flex
            w={80}
            h={80}
            bg="surface"
            shadow={30}
            borderRadius="50%"
            flexCenter
            lat={51.1992976}
            lng={6.422792}
          >
            <img
              src={logo}
              style={{ height: 60, width: 60, marginTop: 30 }}
              alt="WKM Rösner"
            />
          </Flex>
          <Flex
            w={80}
            h={80}
            bg="surface"
            shadow={30}
            borderRadius="50%"
            flexCenter
            lat={51.2642469}
            lng={6.5465479}
          >
            <img
              src={logo}
              style={{ height: 60, width: 60, marginTop: 30 }}
              alt="WKM Rösner"
            />
          </Flex>
        </GoogleMapReact>
      </div>
      <Flex bg="secondary" as="footer">
        <Wrapper flexDirection={["column", "row"]} pt="10vh" pb="10vh">
          <Flex alignItems="flex-start" w="100%" mr={[0, 40]}>
            <Text font="h4" as="h4" color="#FFF">
              Über WKM Rösner
            </Text>
            <Text color="#FFF">
              WKM Rösner (WundKompetenzManagement) ist eine moderne medizinische
              Versorgungsinstitution, die im ambulanten und stationären Bereich
              sowie in einer eigenen Praxis für Wundversorgung WKMzentrum,
              Menschen mit chronischen Wunden behandelt. <br />
              <br />
              Schreiben Sie eine Anfrage, wir melden uns dann so schnell wie
              möglich bei Ihnen.
            </Text>
            <Button to="/kontakt" mt={20} mb={30}>
              Anfrage senden
            </Button>
          </Flex>
          <Flex
            w="100%"
            pl={[0, 40]}
            pt={[30, 0]}
            borderLeft={["0px", "1px solid rgba(255,255,255,0.2)"]}
            borderTop={["1px solid rgba(255,255,255,0.2)", "0px"]}
          >
            <Text font="h5" as="h5" fontWeight="300" color="#FFF">
              So erreichen Sie uns
            </Text>
            <Flex mt="1.4rem" row wrap>
              <Flex mr={40} mb={[30, 1]}>
                <Text color="#FFF">
                  <b>WKM Rösner Mönchengladbach</b>
                  <br />
                  Wir sind in Mönchengladbach umgezogen. Die neue Adresse
                  lautet:
                  <br />
                  <br />
                  Fliethstraße 100
                  <br />
                  41061
                  <br />
                  <Text
                    as="a"
                    color="primary"
                    mt={5}
                    href="mailto:buero@wkmonline.de"
                  >
                    buero@wkmonline.de
                  </Text>
                  <br />
                  Telefon 02161 47745 - 45
                  <br />
                  Fax 02161 47745 - 44
                </Text>
              </Flex>
              <Flex>
                <Text color="#FFF">
                  <b>WKM Rösner Willich</b>
                  <br />
                  Burgstraße 10
                  <br />
                  47877 Willich
                  <br />
                  <Text
                    as="a"
                    mt={5}
                    color="primary"
                    href="mailto:buero@wkmonline.de"
                  >
                    buero@wkmonline.de
                  </Text>
                  <br />
                  Telefon 02154 887 78 - 44
                  <br />
                  Fax 02154 887 78 - 45
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Wrapper>
        <Flex bg="surface" relative>
          <Wrapper pt={25} pb={20} justifyContent="space-between">
            <Text font="label">
              © {new Date().getFullYear()} WKM Rösner. Alle Rechte vorbehalten.
            </Text>
            <Flex row>
              <Button to="/impressum" font="label" py={0} px={5} clean>
                Impressum
              </Button>
              <Button
                to="/datenschutzbestimmungen"
                font="label"
                py={0}
                px={5}
                clean
              >
                Datenschutzbestimmungen
              </Button>
            </Flex>
          </Wrapper>
          <Flex
            w={44}
            h={44}
            bg="primary"
            absolute
            top={-22}
            r={22}
            flexCenter
            onClick={() => {
              if (typeof window !== "undefined") {
                window.scroll({
                  top: 0,
                  behavior: "smooth",
                });
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="#FFFFFF"
              width="36px"
              height="36px"
              style={{ transform: "rotate(180deg)" }}
            >
              <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z" />
              <path d="M0 0h24v24H0V0z" fill="none" />
            </svg>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

const StyledFooter = styled(Footer)`
  color: #fffaff;
  background: var(--primary-color);
  padding: 1rem 0;
`;

export default StyledFooter;
