import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { ThemeProvider } from "../styled";

import { GlobalStyle } from "./globalStyle";
import { MainWrapper } from "./layoutComponents";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <ThemeProvider
        theme={{
          colors: {
            primary: "#E52317",
            secondary: "#00315D",
            background: "#F8FAFB",
          },
          fonts: {
            p: {
              fontSize: "0.9rem",
              fontWeight: 300,
            },
            h3: {
              fontWeight: 400,
            },
            h4: {
              fontWeight: 400,
            },
            h5: {
              fontSize: "1.1rem",
              fontWeight: 400,
            },
          },
        }}
      >
        <GlobalStyle />
        <MainWrapper>
          <Header siteTitle={data.site.siteMetadata.title} />
          <main style={{ marginTop: 60 }}>{children}</main>
          <Footer />
        </MainWrapper>
      </ThemeProvider>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
