import * as React from "react";

import { ThemeProvider as TP } from "styled-components";
import defaultTheme from "./defaultTheme";

export function deepmerge(target, source) {
  const isObject = (obj) => obj && typeof obj === "object";

  if (!isObject(target) || !isObject(source)) {
    return source;
  }

  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      target[key] = targetValue.concat(sourceValue);
    } else if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = deepmerge(Object.assign({}, targetValue), sourceValue);
    } else {
      target[key] = sourceValue;
    }
  });

  return target;
}

export const ThemeProvider = ({ theme, mode, children }) => {
  const mergedTheme = React.useMemo(() => {
    const merged = deepmerge(defaultTheme, theme);
    if (mode && merged.colors.modes[mode]) {
      merged.colors = deepmerge(merged.colors, merged.colors.modes[mode]);
    }
    return merged;
  }, [theme, mode]);
  return (
    <TP
      theme={{
        ...mergedTheme,
        width: typeof window === "undefined" ? null : window.width,
        height: typeof window === "undefined" ? null : window.height,
        mode,
      }}
    >
      {children}
    </TP>
  );
};
