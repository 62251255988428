import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { Container } from "./layoutComponents";

import { Flex } from "../styled";

import logo from "../images/wkm_logo_neu.svg";

const StyledBurger = styled.button`
  display: none;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 10;

  @media only screen and (max-width: 768px) {
    display: flex;
  }

  &:focus {
    outline: none;
  }

  div {
    width: 2rem;
    height: 0.2rem;
    background: ${({ open, theme }) =>
      open ? theme.colors.primary : theme.colors.secondary};
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

const Header = ({ siteTitle, className }) => {
  const [open, setOpen] = React.useState(false);
  return (
    <Flex
      className={className}
      w="100vw"
      bg="surface"
      h={[60, 80]}
      shadow={50}
      as="header"
      zIndex={9999}
      flexCenter
    >
      <Container
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Link to="/" style={{ height: 60, width: 120 }}>
          <img src={logo} style={{ height: 60, width: 120 }} alt="WKM Rösner" />
        </Link>

        <Flex as="nav" className={open ? "open" : ""} h={20} row>
          <Link to="/" activeClassName="active">
            Für Patienten
          </Link>
          <Link to="/aerzte" activeClassName="active">
            Für Ärzte
          </Link>
          <Link to="/schulungen" activeClassName="active">
            Schulungen
          </Link>
          {/* <Link to="/news" activeClassName="active">
            News & Presse
          </Link> */}
          <Link to="/kontakt" activeClassName="active">
            Kontakt
          </Link>
        </Flex>
        <Burger open={open} setOpen={setOpen} />
      </Container>
    </Flex>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default styled(Header)`
  position: fixed;
  top: 0px;
  left: 0;

  nav {
    a {
      color: ${(p) => p.theme.colors.secondary};
      margin-left: 20px;
      transition: 300ms all ease-in-out;
      font-size: 0.9rem;

      &:hover,
      &.active {
        color: ${(p) => p.theme.colors.primary};
      }
    }

    @media only screen and (max-width: 768px) {
      position: fixed;
      background-color: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(5px);
      top: 60px;
      left: 0;
      width: 100vw;
      height: calc(100vh - 60px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 9999;
      opacity: 0;
      pointer-events: none;
      transition: 300ms all ease-in-out;
      transform: translate3d(0, 50px, 0);

      &.open {
        opacity: 1;
        pointer-events: all;
        transform: translate3d(0, 0, 0);
      }

      a {
        font-size: 1.5rem;
        padding: 20px 0;
      }
    }
  }
`;
