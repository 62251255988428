import { styled } from "../styled";
import { Link } from "gatsby";

const StyledLink = styled(Link)(({ clean, font = "p" }) => ({
  bg: clean ? "transparent" : "primary",
  color: clean ? "primary" : "#FFF",
  p: "10px 25px",
  font: font,
}));

export default StyledLink;
