import * as React from "react";
import { default as SCstyled } from "styled-components";

import { matchProps, boxFunctions } from "./styledFunctions";

export function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

const styled = (BaseComponent) => (BaseStyle = {}) => {
  return SCstyled(BaseComponent)((p) =>
    matchProps(
      p,
      boxFunctions,
      isFunction(BaseStyle) ? BaseStyle(p) : BaseStyle
    )
  );
};

styled["H1"] = styled("h1");
styled["Text"] = styled("p");
styled["Box"] = styled("div");
styled["View"] = styled("div");
styled["Touchable"] = styled("div");
styled["Image"] = styled("img");

export const Flex = styled.Box(({ row }) => ({
  display: "flex",
  flexDirection: row ? "row" : "column",
}));

const TextComp = styled.Text({});

export const Text = ({ children, font = "p", ...rest }) => {
  return (
    <TextComp font={font} as={font} {...rest}>
      {children}
    </TextComp>
  );
};

export { styled };
